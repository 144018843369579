import React, { useState } from 'react';
import { Form, Formik, useFormik, Field, useField } from 'formik';
import Checkbox from 'react-custom-checkbox';
import { CheckboxTick } from '../Icon';
import SignUpField from './SignUpField';
import SignUpFieldLrg from './SignUpFieldLrg';
import SignUpHubspotForm from './HubspotSignUp';

const SignupForm = ({ layout, hideCheckbox, uniqueId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setIsSubmitted] = useState(false);

  if (layout === 'large') {
    return (
      // <Formik
      //   initialValues={{
      //     email: '',
      //     blog: true,
      //     commentary: true,
      //     product: true,
      //     everything: true,
      //   }}
      //   onSubmit={async (values, { setFieldValue }) => {
      //     const formData = new FormData();

      //     for (var key in values) {
      //       formData.append(key, values[key]);
      //     }

      //     fetch('https://hooks.zapier.com/hooks/catch/10170231/bw3oatv/', {
      //       method: 'POST',
      //       body: formData,
      //     })
      //       .then((response) => {
      //         if (response.status === 200) {
      //           setIsSubmitted(true);
      //         }
      //       })
      //       .catch((error) => {
      //         setErrorMessage(error);
      //       });
      //     setErrorMessage(''); // reset error message
      //   }}
      // >
      //   <Form className="text-left mb-4 md:mb-0">
      //     <div className="w-full max-w-[762px] mx-auto flex flex-col sm:flex-row my-8 md:mb-[56px] md:space-x-4 justify-center sm:justify-between">
      //       <Checkboxes large={true} />
      //     </div>
      //     <div className="sm:flex font-heading space-y-4 sm:space-y-0">
      //       <fieldset className="sm:-mr-12 t-18 w-full sm:w-[90%]">
      //         <SignUpFieldLrg name="email" label="Email Address" type="email" />
      //       </fieldset>
      //       <button
      //         className="w-full sm:w-auto bg-blue hover:bg-navy z-10 transition-colors duration-200 text-white rounded-[45px] h-[60px] px-[30px] sm:px-[60px] lg:px-[90px] flex items-center t-16 font-bold space-x-[9px] whitespace-nowrap justify-center mx-auto md:ml-0"
      //         type="submit"
      //       >
      //         <span>Subscribe</span>
      //         <Star />
      //       </button>
      //     </div>
      //     <div>
      //       {submitted && (
      //         <div className="p-4 mt-4 text-blue text-center">
      //           Thanks for getting in touch, we'll get back to you soon.
      //         </div>
      //       )}
      //     </div>
      //   </Form>
      // </Formik>
      <SignUpHubspotForm displayID={uniqueId} isLarge />
    );
  } else {
    return (
      // <Formik
      //   initialValues={{
      //     email: '',
      //     blog: true,
      //     commentary: true,
      //     product: true,
      //     everything: true,
      //   }}
      //   onSubmit={async (values, { setFieldValue }) => {
      //     const formData = new FormData();

      //     for (var key in values) {
      //       formData.append(key, values[key]);
      //     }

      //     fetch('https://hooks.zapier.com/hooks/catch/10170231/bw3oatv/', {
      //       method: 'POST',
      //       body: formData,
      //     })
      //       .then((response) => {
      //         if (response.status === 200) {
      //           setIsSubmitted(true);
      //         }
      //       })
      //       .catch((error) => {
      //         setErrorMessage(error);
      //       });
      //     setErrorMessage(''); // reset error message
      //   }}
      // >
      //   <Form className="text-left">
      //     <div className="sm:flex font-heading space-y-4 sm:space-y-0">
      //       <fieldset className="sm:-mr-8 t-18 w-full sm:w-[95%]">
      //         <SignUpField name="email" label="Email Address" type="email" required={true} />
      //       </fieldset>
      //       <button
      //         className="z-10 w-full sm:w-auto bg-blue text-white rounded-[20px] h-[40px] px-[30px] flex items-center t-16 font-bold space-x-[9px] whitespace-nowrap justify-center mx-auto md:ml-0"
      //         type="submit"
      //       >
      //         <span>Subscribe</span>
      //         <Star />
      //       </button>
      //     </div>
      //     {!hideCheckbox && (
      //       <div className="w-full flex flex-col sm:flex-row flex-wrap my-8 md:space-x-4 justify-center sm:justify-between">
      //         <Checkboxes large={false} />
      //       </div>
      //     )}
      //     {submitted && (
      //       <div className="p-4 mt-4 text-blue text-center">
      //         Thanks for getting in touch, we'll get back to you soon.
      //       </div>
      //     )}
      //   </Form>
      // </Formik>
      <SignUpHubspotForm displayID={uniqueId} />
    );
  }
};

export const Star = () => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.93504 10.0078C4.97857 8.7972 5.47941 7.6511 6.3322 6.81041C7.18499 5.96973 8.32328 5.49998 9.50772 5.50001C8.32328 5.50005 7.18499 5.03034 6.3322 4.18965C5.47941 3.34896 4.97857 2.20283 4.93504 0.992249C4.89137 2.17946 4.40812 3.3053 3.58318 4.14173C2.75824 4.97816 1.65331 5.46262 0.492188 5.49699C1.6543 5.53145 2.76004 6.01676 3.58513 6.85447C4.41021 7.69217 4.89283 8.81951 4.93504 10.0078Z"
      fill="white"
    />
  </svg>
);

const Checkboxes = ({ large }) => {
  const [blogChecked, setBlogChecked] = useState(false);
  const [marketChecked, setMarketChecked] = useState(true);
  const [productChecked, setProductChecked] = useState(true);
  const [everythingChecked, setEverythingChecked] = useState(true);

  return (
    <>
      <fieldset
        className={`flex items-center sm:w-1/2 mb-4 md:mb-0 md:w-auto gap-2 sm:gap-3 ${large ? 'pr-4' : 'pr-1'}`}
      >
        <label className="flex items-center">
          <Field
            className="w-[24px] h-[24px]"
            type="checkbox"
            name="blog"
            id="blog"
            size={24}
            style={{ cursor: 'pointer' }}
            label="Blog"
          />
          <span className="ml-2">Blog</span>
        </label>
      </fieldset>
      <fieldset
        className={`flex items-center sm:w-1/2 mb-4 md:mb-0 md:w-auto gap-2 sm:gap-3 ${large ? 'pr-4' : 'pr-1'}`}
      >
        <label className="flex items-center">
          <Field
            className="w-[24px] h-[24px]"
            type="checkbox"
            name="commentary"
            id="commentary"
            size={24}
            style={{ cursor: 'pointer' }}
            label="commentary"
          />
          <span className="ml-2">Market Commentary</span>
        </label>
      </fieldset>
      <fieldset
        className={`flex items-center sm:w-1/2 mb-4 md:mb-0 md:w-auto gap-2 sm:gap-3 ${large ? 'pr-4' : 'pr-1'}`}
      >
        <label className="flex items-center">
          <Field
            className="w-[24px] h-[24px]"
            type="checkbox"
            name="product"
            id="product"
            size={24}
            style={{ cursor: 'pointer' }}
            label="product"
          />
          <span className="ml-2">Product Updates</span>
        </label>
      </fieldset>
      <fieldset
        className={`flex items-center sm:w-1/2 mb-4 md:mb-0  md:w-auto gap-2 sm:gap-3 ${large ? 'pr-4' : 'pr-1'}`}
      >
        <label className="flex items-center">
          <Field
            className="w-[24px] h-[24px]"
            type="checkbox"
            name="everything"
            id="everything"
            size={24}
            style={{ cursor: 'pointer' }}
            label="everything"
          />
          <span className="ml-2">Everything</span>
        </label>
      </fieldset>
    </>
  );
};

export default SignupForm;

// Styled checkbox
{
  /* <Checkbox
icon={<CheckboxTick />}
name="everything"
id="everything"
onChange={(value) => {
  setEverythingChecked(value);
}}
checked={true}
borderColor={everythingChecked ? "#2554FD" : 'rgba(0, 0, 0, 0.1)' }
borderRadius={everythingChecked ? "4px" : '2px' }
size={24}
style={{ cursor: "pointer" }}
labelStyle={{ marginLeft: 10, userSelect: "none" }}
label="Everything"></Checkbox> */
}
