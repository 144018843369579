import React from 'react';
// elements
import HubspotForm from './HubspotForm';

export default function SignUpHubspotForm({ displayID, isLarge = false }) {
  return (
    <div className={`hs-signup-form w-full ${isLarge ? 'form-large' : ''}`}>
      <HubspotForm
        region="na1"
        portalId="21474530"
        formId="44a7554a-2ac3-4bbd-8b15-d5bceaa4c28e"
        displayID={displayID}
      />
    </div>
  );
}
