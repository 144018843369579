import React from "react";
import { Section } from "~/components/elements/Section";
import { WpPage_Page_FlexibleContent_TwoColumn } from "~/_generated/types";

export default function TwoColumn({
  heading,
  content,
  links,
  image,
  section,
}: WpPage_Page_FlexibleContent_TwoColumn) {

  return (
    <Section {...section}>
      <div className="container">
        <div className="grid grid-cols-2">
          <div>Content</div>
          <div>Image</div>
        </div>
      </div>
    </Section>
  );
}
